import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import axios from "axios";
import {AppConstants, GetBasicAuthHeader, GetWebServiceUrl} from "../../../constants/appConstants";
import {useState} from "react";

export default function RequestComponent() {
    const requests = useSelector(state => state.requests.requests);
    const admin = useSelector((state) => state.admin.admin);
    const [state, setState] = useState(0)

    const accept = (index) => {
        axios.post(GetWebServiceUrl(AppConstants.ACCEPT_REQUEST_URL),
            requests[index],
            GetBasicAuthHeader(admin)).catch(err => console.log(err)).then(response => {
            if (response.status === 200) {
                console.log(response.data);
                requests.splice(index, 1);
                setState(state + 1)
            } else {
                alert(response);
            }
        });
    }

    const reject = (index) => {
        axios.post(GetWebServiceUrl(AppConstants.REJECT_REQUEST_URL),
            requests[index],
            GetBasicAuthHeader(admin)).catch(err => console.log(err)).then(response => {
            if (response.status === 200) {
                console.log(response.data);
                requests.splice(index, 1);
                setState(state + 1)
            } else {
                alert(response);
            }
        });
    }


    const renderList = requests.map((request, index) => {
        return (
            <div key={request.ID} className={'game-list-widget'}>
                <p className={'float-right white'}>درخواست عضویت</p>
                <button onClick={() => accept(index)}
                        className={'float-right publish-button'}>قبول
                </button>
                <button onClick={() => reject(index)}
                        className={'float-right unpublish-button'}>رد
                </button>
                <p className={'float-left white'}>{request.data}</p>
            </div>
        );
    })


    return (
        <>
            <p hidden={requests.length > 0} className={'white center'}>من، خالی از عاطفه و خشم</p>
            {renderList}
        </>
    )
}