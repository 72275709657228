import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import axios from "axios";
import {AppConstants, GetBasicAuthHeader, GetWebServiceUrl} from "../../../constants/appConstants";
import {selectedProduct} from "../../../redux/actions/productActions";
import {useState} from "react";
import {SetNotification} from "../../ui/NotificationHeader";


export default function GamesComponent() {


    const games = useSelector((state) => state.allProducts.products)
    const [state, setState] = useState(0);
    const admin = useSelector(state => state.admin.admin)
    const dispatch = useDispatch();


    const selectGame = (index) => {
        dispatch(selectedProduct(games[index]))
    }

    const removeGame = (index) => {
        console.log("REMOVE GAME");
        axios.post(GetWebServiceUrl(AppConstants.DELETE_GAME_URL),
            {"clientId": games[index].clientId},
            GetBasicAuthHeader(admin)).catch(err => console.log(err)).then(response => {
            console.log(response.data);
            games.splice(index, 1);
            console.log(games);
            setState(state + 1)
        });
    }

    const publishGame = (index) => {
        axios.post(GetWebServiceUrl(AppConstants.PUBLISH_GAME_URL),
            {"clientId": games[index].clientId},
            GetBasicAuthHeader(admin)).catch(err => console.log(err)).then(response => {
                if (response.status === 200){
                    console.log(response.data);
                    games[index].isAvailable = true;
                    setState(state + 1)
                    SetNotification("انتشار بازی","بازی با موفقیت منتشر شد","success");

                }else{
                    SetNotification("خطا", response.status + "\n" + response.data,"danger");
                }

        });
    }

    const unPublishGame = (index) => {
        axios.post(GetWebServiceUrl(AppConstants.UN_PUBLISH_GAME_URL),
            {"clientId": games[index].clientId},
            GetBasicAuthHeader(admin)).catch(err => console.log(err)).then(response => {
            if (response.status === 200){
                console.log(response.data);
                games[index].isAvailable = false;
                setState(state + 1)
                SetNotification("لغو انتشار بازی","بازی با موفقیت لغو انتشار شد","success");
            }else{
                SetNotification("خطا", response.status + "\n" + response.data,"danger");
            }
        });
    }

    const renderList = games.map((game, index) => {
        return (

            <div key={game.ID} className={'game-list-widget'}>
                <button onClick={() => removeGame(index)}
                        className={'float-right remove-button'}>زدایش
                </button>
                <button hidden={game.isAvailable || admin.role === 0} onClick={() => publishGame(index)}
                        className={'float-right publish-button'}>انتشار
                </button>
                <button hidden={!game.isAvailable  || admin.role === 0} onClick={() => unPublishGame(index)}
                        className={'float-right unpublish-button'}>لغو انتشار
                </button>
                <Link onClick={() => selectGame(index)} to={`${game.clientId}`} className='game-list-card'>

                    <img className='game-list-info game-list-img'
                         src={game.imagePath}/>
                    <div className='game-list-info'>
                        <p>Name: <b>{game.name}</b></p>
                        <p>Client id: <b>{game.clientId}</b></p>
                        <p>IsAvailable?: <b>{game.isAvailable.toString()}</b></p>
                        <p>Update time: <b>{game.UpdatedAt}</b></p>
                    </div>

                </Link>
            </div>
        );
    })

    return (
        <>{renderList}</>
    )
}