import '../ui/style.css'
import {Link, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

export default function DevelopHeader(props) {

    const removeAllCookies = async () => {
        console.log("Remove All Cookies");
        window.sessionStorage.removeItem("access_token");
        window.sessionStorage.removeItem("nama");
    }
    const navigation = useNavigate();
    const logout = response => {
        window.sessionStorage.removeItem("access_token");
        window.sessionStorage.removeItem("nama");
        navigation("/");
    }
    const clientId = '301482692908-b9m9erlei7md1e1ck78vtuvi755964o2.apps.googleusercontent.com';


    let admin = useSelector(state => state.admin.admin)
    return (
        <nav>
            <ul className='nav-header'>
                <li>
                    <img className='profile-img' src={admin.avatarUrl} alt={admin.email}/>
                </li>
                <li>
                    <Link to="/profile" className={IsActive(0, props)}>{admin.fullName}</Link>
                </li>
                <li>
                    <Link to="/games" className={IsActive(1, props)}>Games</Link>
                </li>
                <li hidden={admin.role === 0}>
                    <Link to="/requests" className={IsActive(2, props)}>Requests</Link>
                </li>
                <li hidden={admin.role === 0}>
                    <Link to="/avatars" className={IsActive(3, props)}>Avatar</Link>
                </li>
                <li hidden={admin.role === 0}>
                    <Link to="/finance" className={IsActive(4, props)}>Finance</Link>
                </li>
                <li hidden={admin.role === 0}>
                    <Link to="/tournaments" className={IsActive(5, props)}>Tournaments</Link>
                </li>
                <li hidden={admin.role === 0}>
                    <Link to="/tasks" className={IsActive(6, props)}>Tasks</Link>
                </li>
            </ul>
        </nav>
    );
}


function IsActive(pageId, props) {
    let data = ""
    if (pageId === props.pageId) {
        data += "active";
    }
    if (props.role === 0) {
        data += " hidden";
    }
    return data
}