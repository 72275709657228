import {ActionTypes} from "../constants/action-types";

const dailyInitialState = {
    tasks: [],
};

const bonusInitialState = {
    tasks: [],
};

export const dailyTasksReducer = (state = dailyInitialState, {type, payload}) =>{
    switch (type){
        case ActionTypes.SET_DAILY_TASKS:
            return {...state,tasks:payload}
        default:
            return state
    }
};

export const bonusTasksReducer = (state = bonusInitialState, {type, payload}) =>{
    switch (type){
        case ActionTypes.SET_BONUS_TASKS:
            return {...state,tasks:payload}
        default:
            return state
    }
};