import {ActionTypes} from "../constants/action-types";

export const setDailyTasks = (tasks) =>{
    return {
        type: ActionTypes.SET_DAILY_TASKS,
        payload: tasks,
    };
};

export const setBonusTasks = (tasks) =>{
    return {
        type: ActionTypes.SET_BONUS_TASKS,
        payload: tasks,
    };
};