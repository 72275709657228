export const uintToRank = (val) => {
    switch (val) {
        case 0:
            return "Practice"
        case 2:
            return "Bronze"
        case 3:
            return "Silver"
        case 4:
            return "Gold"
        case 5:
            return "Diamond"
        case 6:
            return "Champion"
        case 7:
            return "Legend"
    }
}

export const uintToRankFa = (val) => {
    switch (val) {
        case 0:
            return "تمرینی"
        case 2:
            return "برنزی"
        case 3:
            return "نقره ای"
        case 4:
            return "طلایی"
        case 5:
            return "الماس"
        case 6:
            return "قهرمانان"
        case 7:
            return "افسانه ای"
    }
}

export const rankToUint = (val) => {
    switch (val) {
        case "Practice":
            return 0
        case "Bronze":
            return 2
        case "Silver":
            return 3
        case "Gold":
            return 4
        case "Diamond":
            return 5
        case "Champion":
            return 6
        case "Legend":
            return 7
    }
}

