import DevelopHeader from "../../ui/developHeader";
import NotificationHeader from "../../ui/NotificationHeader";
import {AppConstants, GetBasicAuthHeader, GetWebServiceUrl} from "../../../constants/appConstants";
import axios from "axios";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {setFinance} from "../../../redux/actions/financeActions";
import FinanceChart from "./financeChart";
const old = Number.prototype.toLocaleString;
Number.prototype.toLocaleString = function (locale) {
    let result = old.call(this, locale);
    if (locale === 'fa-IR') {
        result = result.replace(/\٬/g, ",‬");
    }
    return result;
}
export const formatNumber = (q) => {
    return q.toLocaleString('en-US', {
        style: 'currency',
        currency: 'fa-IR'
    })
}

export default function FinancePage() {
    const admin = useSelector((state) => state.admin.admin);
    const navigation = useNavigate();
    const finance = useSelector((state) => state.finance.data);
    const dispatch = useDispatch();

    const fetchFinance = async () => {
            console.log("START FETCH FINANCE")
            let response = await axios.get(GetWebServiceUrl(AppConstants.GET_FINANCE), GetBasicAuthHeader(admin)).catch(err => {
                console.log(err)
            })
            console.log(response.data)
            dispatch(setFinance(response.data))
        // matchData = {
        //     f.match.silverChart.labels,
        //     datasets:[
        //         {
        //             label:'Silver',
        //             data:f.match.silverChart.data,
        //             backgroundColor: 'rgba(255, 99, 132, 0.5)',
        //         }
        //     ]
        // };

    }

    const getMatchData = (f) => {
        const labels = f.match.silverChart.labels;
        const chartData = f.match.silverChart.data;
        const matchData = {
            labels,
            datasets: [
                {
                    label: "نقره",
                    data: chartData,
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                }
            ]
        }
        console.log("get match data", matchData)
        return matchData
    }


    useEffect(() => {
        if (admin.token === undefined) {
            navigation("/");
        }
        fetchFinance();
    }, []);



    if (finance == null) return
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Chart.js Bar Chart',
            },
        },
    };


    return (
        <>
            <DevelopHeader pageId={4}></DevelopHeader>
            <NotificationHeader></NotificationHeader>
            <section className='fix-position full-width '>
                <div className={'force-white game-list-widget'}>
                    <h1>Matches</h1>
                    <h3>طلا</h3>
                    <p>ورودی:<b>{formatNumber(finance.match.inGold)}</b></p>
                    <p>خروجی:<b>{formatNumber(finance.match.outGold)}</b></p>
                    <div className='reward-wrapper force-black'>
                        <FinanceChart data={finance.match.goldChart}></FinanceChart>
                    </div>
                    <h3>نقره</h3>
                    <p>ورودی:<b>{formatNumber(finance.match.inSilver)}</b></p>
                    <p>خروجی:<b>{formatNumber(finance.match.outSilver)}</b></p>
                    {/*<p>{finance.match.silverChart.labels}</p>*/}
                    {/*<p>{finance.match.silverChart.data}</p>*/}
                    <div className='reward-wrapper force-black'>
                        <FinanceChart data={finance.match.silverChart}></FinanceChart>
                    </div>
                </div>

                <div className={'force-white game-list-widget'}>

                    <h1>Shop</h1>
                    <h3>فروش</h3>
                    <p>تومان <b>{formatNumber(finance.shop.money)}</b></p>
                    <div className='reward-wrapper force-black'>
                        <FinanceChart data={finance.shop.moneyChart}></FinanceChart>
                    </div>
                    <p>طلای فروحته شده:<b>{formatNumber(finance.shop.gold)}</b></p>
                    <div className='reward-wrapper force-black'>
                        <FinanceChart data={finance.shop.goldChart}></FinanceChart>
                    </div>
                </div>
                <div className={'force-white game-list-widget'}>
                    <h1>Third Party</h1>
                    <h3>طلا</h3>
                    <p>ورودی:<b>{formatNumber(finance.thirdParty.inGold)}</b></p>
                    <p>خروجی:<b>{formatNumber(finance.thirdParty.outGold)}</b></p>
                    <div className='reward-wrapper force-black'>
                        <FinanceChart data={finance.thirdParty.goldChart}></FinanceChart>
                    </div>
                    <h3>نقره</h3>
                    <p>ورودی:<b>{formatNumber(finance.thirdParty.inSilver)}</b></p>
                    <p>خروجی:<b>{formatNumber(finance.thirdParty.outSilver)}</b></p>
                    {/*<p>{finance.match.silverChart.labels}</p>*/}
                    {/*<p>{finance.match.silverChart.data}</p>*/}
                    <div className='reward-wrapper force-black'>
                        <FinanceChart data={finance.thirdParty.silverChart}></FinanceChart>
                    </div>
                </div>
            </section>
        </>
    )
}