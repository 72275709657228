import { useDropzone } from 'react-dropzone';

import styled from 'styled-components';
const getColor = (props) => {
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isFocused) {
        return '#2196f3';
    }
    return '#eeeeee';
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  border-width: 2px;
  border-radius: 10px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: rgba(0, 0, 0, 0);
  color: white;
  font-weight: bold;
  font-size: 1.4rem;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

export default function DropBox({ onDrop }) {
    const {
        getRootProps,
        getInputProps,
        acceptedFiles,
        open,
        isDragAccept,
        isFocused,
        isDragReject,
    } = useDropzone({
        accept: 'image/*',
        onDrop,
        noClick: true,
        noKeyboard: true,
    });

    const lists = acceptedFiles.map((list) => (
        <h6  key={list.path}>
            {list.path} - {list.size} bytes
        </h6>
    ));

    return (
        <>
            {' '}
            <section className="dropbox">
                <Container
                    className="dropbox"
                    {...getRootProps({ isDragAccept, isFocused, isDragReject })}
                >
                    <input {...getInputProps()} />
                    <p>برای آپلود فایل را در اینجا رها کنید یا با استفاده از دکمه زیر آپلود کنید</p>
                    <button type="button" className="blue-button" onClick={open}>
                        انتخاب فایل
                    </button>
                        <p>{lists}</p>
                </Container>

            </section>

        </>
    );
}

