import {useCallback, useState} from "react";
import GameReward from "./gameReward";
import {useSelector} from "react-redux";
import {rankToUint, RankTypes, uintToRank, uintToRankFa} from "./rank-types";
import {currencyToUint, uintToCurrency} from "./currency-types";
import axios from "axios";
import {AppConstants, GetBasicAuthHeader, GetWebServiceUrl} from "../../../../constants/appConstants";
import {ReactNotifications, Store} from "react-notifications-component";
import {SetNotification} from "../../../ui/NotificationHeader";




export const saveGameMode = async (mode,admin) => {
    let data = {
        "ID": mode.ID,
        "rank": mode.rank,
        "trophyReward": parseInt(mode.trophyReward),
        "loseTrophy": parseInt(mode.loseTrophy),
        "winXp": parseInt(mode.winXp),
        "loseXp": parseInt(mode.loseXp),
        "rewards": mode.rewards,
        "entrance": parseInt(mode.entrance),
        "entranceType": mode.entranceType,
    }

    let response = await axios.post(GetWebServiceUrl(AppConstants.SAVE_GAME_MODE_URL), data, GetBasicAuthHeader(admin)).catch(err => {
        console.log(err);
    });
    if (response.status === 200){
        SetNotification("ذخیره مود بازی","مود بازی با موفقیت تغییر کرد","success");
    }else{
        SetNotification("خطا", response.status + "\n" + response.data,"danger");
    }
}



export default function GameMode() {

    const gameModes = useSelector((state) => state.gameModes.modes);
    const admin = useSelector(state => state.admin.admin);
    const [state, setState] = useState(0);
    const addReward = (mode) => {
        let index = gameModes.findIndex(t => t.ID == mode.ID);
        let reward = {"amount": 0, "type": 1};
        if (gameModes[index].rewards != null) {
            gameModes[index].rewards.push(reward);
        } else {
            gameModes[index].rewards = [reward];
        }
        setState(state + 1);
        saveGameMode(mode,admin)
    }


    const onChangeWinXp = (index, value) => {
        value = parseInt(value)
        if (value < 0) {
            value = 0
        }

        gameModes[index].winXp = value;
        setState(state + 1);
    }
    const onChangeTrophyReward = (index, value) => {
        value = parseInt(value)
        if (value < 0) {
            value = 0
        }
        gameModes[index].trophyReward = value;
        setState(state + 1);
    }
    const onChangeLoseTrophy = (index, value) => {
        value = parseInt(value)
        if (value < 0) {
            value = 0
        }
        gameModes[index].loseTrophy = value;
        setState(state + 1);
    }
    const onChangeEntrance = (index, value) => {
        value = parseInt(value)
        if (value < 0) {
            value = 0
        }
        gameModes[index].entrance = value;
        setState(state + 1);
    }
    const onChangeLoseXp = (index, value) => {
        value = parseInt(value)
        if (value < 0) {
            value = 0
        }
        gameModes[index].loseXp = value;
        console.log(gameModes[index].loseXp);
        setState(state + 1);
    }
    const onChangeRank = (index, value) => {
        gameModes[index].rank = rankToUint(value);
        setState(state + 1);
    }
    const onChangeEntranceType = (index, value) => {
        gameModes[index].entranceType = currencyToUint(value);
        setState(state + 1);
    }

    const onRemoveGameMode = (index) => {
        console.log("REMOVE GAME MODE");
        axios.post(GetWebServiceUrl(AppConstants.DELETE_GAME_MODE_URL),
            {"modeId": gameModes[index].ID},
            GetBasicAuthHeader(admin)).catch(err => console.log(err)).then(response => {
            gameModes.splice(index, 1)
            console.log(response.data);
            setState(state + 1);
        });
    }


    const publishGameMode = (index) => {
        axios.post(GetWebServiceUrl(AppConstants.PUBLISH_GAME_MODE_URL),
            {"modeId": gameModes[index].ID},
            GetBasicAuthHeader(admin)).catch(err => console.log(err)).then(response => {
            if (response.status === 200){
                console.log(response.data);
                gameModes[index].isAvailable = true;
                setState(state + 1)
                SetNotification("انتشار مود بازی","مود بازی با موفقیت منتشر شد","success");
            }else{
                SetNotification("خطا", response.status + "\n" + response.data,"danger");
            }
        });
    }

    const unPublishGameMode = (index) => {
        axios.post(GetWebServiceUrl(AppConstants.UN_PUBLISH_GAME_MODE_URL),
            {"modeId": gameModes[index].ID},
            GetBasicAuthHeader(admin)).catch(err => console.log(err)).then(response => {
            if (response.status === 200){
                console.log(response.data);
                gameModes[index].isAvailable = false;
                setState(state + 1)
                SetNotification("لغو انتشار مود بازی","مود بازی با موفقیت لغو انتشار شد","success");
            }else{
                SetNotification("خطا", response.status + "\n" + response.data,"danger");
            }
        });
    }

    const renderList = gameModes.map((mode, index) => {
        return (
            <div key={mode.ID} className={'game-mode-list padding margin '}>

                <button onClick={() => onRemoveGameMode(index)}
                        className={'float-right remove-button force-black'}>زدایش
                </button>
                <button hidden={mode.isAvailable || admin.role === 0} onClick={() => publishGameMode(index)}
                        className={'float-right publish-button'}>انتشار
                </button>
                <button hidden={!mode.isAvailable  || admin.role === 0} onClick={() => unPublishGameMode(index)}
                        className={'float-right unpublish-button'}>لغو انتشار
                </button>
                <h1>{uintToRankFa(mode.rank)}</h1>
                <table className={'float-right sep-table'}>
                    <td>
                        <select onChange={(e) => onChangeRank(index, e.target.value)}
                                className={'select-blue-button center'} defaultValue={uintToRank(mode.rank)}>
                            <option value="Practice">تمرینی</option>
                            <option value="Bronze">برنزی</option>
                            <option value="Silver">نقره ای</option>
                            <option value="Gold">طلایی</option>
                            <option value="Diamond">الماس</option>
                            <option value="Champion">قهرمانان</option>
                            <option value="Legend">افسانه ای</option>
                        </select>
                    </td>
                    <td>
                        نوع بازی
                    </td>
                </table>

                <table className={'float-right sep-table'}>
                    <td>
                        <input onChange={(e) => onChangeWinXp(index, e.target.value)} className='mode-input center'
                               type='number' defaultValue={mode.winXp}/>
                    </td>
                    <td>
                        برد XP
                    </td>
                </table>
                <table className={'float-right sep-table'}>
                    <td>
                        <input onChange={(e) => onChangeLoseXp(index, e.target.value)} className='mode-input center'
                               type='number' defaultValue={mode.loseXp}/>
                    </td>
                    <td>
                        باخت XP
                    </td>
                </table>
                <table className={'float-right sep-table'}>
                    <td>
                        <input onChange={(e) => onChangeTrophyReward(index, e.target.value)}
                               className='mode-input center' type='number' defaultValue={mode.trophyReward}/>
                    </td>
                    <td>
                        تروفی برنده
                    </td>
                </table>
                <table className={'float-right sep-table'}>
                    <td>
                        <input onChange={(e) => onChangeLoseTrophy(index, e.target.value)}
                               className='mode-input center' type='number' defaultValue={mode.loseTrophy}/>
                    </td>
                    <td>
                        تروفی بازنده
                    </td>
                </table>
                <table className={'float-right sep-table'}>
                    <td>
                        <input onChange={(e) => onChangeEntrance(index, e.target.value)} className='mode-input center'
                               type='number' defaultValue={mode.entrance}/>
                    </td>
                    <td>
                        ورودی
                    </td>
                </table>
                <table className={'float-right sep-table'}>
                    <td>
                        <select onChange={(e) => onChangeEntranceType(index, e.target.value)}
                                className={'select-blue-button center'}
                                defaultValue={uintToCurrency(mode.entranceType)}>
                            <option value="Money">پول (ریال)</option>
                            <option value="Gold">طلا</option>
                            <option value="Silver">نقره</option>
                        </select>
                    </td>
                    <td>
                        نوع ورودی
                    </td>
                </table>
                <button onClick={() => addReward(mode)} className='dash-button force-black'>اضافه کردن جایزه +</button>
                <div>
                    <GameReward mode={mode}/>
                </div>
                <button onClick={() => saveGameMode(mode,admin)} className='blue-button'>ذخیره</button>
            </div>
        )

    });


    return (
        <>{renderList}</>
    )
}