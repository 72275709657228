import {useSelector} from "react-redux";

export default function GameOption({onChange,defaultValue}) {
    const games = useSelector((state) => state.allProducts.products)

    const renderList = games.map((game, index) => {
        return (
            <option value={game.ID}>{game.name}</option>
        );
    })

    return (
        <select defaultValue={defaultValue} className={'select-btn center'} onChange={e => onChange(e.target.value)}>{renderList}</select>
    )
}
