import DevelopHeader from "../../ui/developHeader";
import axios from "axios";
import {AppConstants, GetBasicAuthHeader, GetWebServiceUrl} from "../../../constants/appConstants";
import {setProducts} from "../../../redux/actions/productActions";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setRequests} from "../../../redux/actions/requestActions";
import GamesComponent from "../Games/gamesComponent";
import RequestComponent from "./requestComponent";
import {useNavigate} from "react-router-dom";


export default function RequestsPage() {
    const requests = useSelector(state => state.requests.requests);
    const admin = useSelector((state) => state.admin.admin);
    const dispatch = useDispatch();
    const navigation = useNavigate();

    const fetchRequests = async () => {
        const response = (await axios.get(GetWebServiceUrl(AppConstants.GET_ALL_REQUESTS_URL), GetBasicAuthHeader(admin))
            .catch(err => {
                console.log("Err: ", err);
            }));

        if (response.status === 200) {
            dispatch(setRequests(response.data))
        }
    }
    useEffect(() => {
        if (admin.token === undefined) {
            navigation("/");
        }
        fetchRequests();
    }, []);

    return (
        <div>
            <DevelopHeader pageId={2}></DevelopHeader>
            <section className='fix-position full-width'>
                <div className="game-list">
                    <div id='requests'>
                        <RequestComponent/>
                    </div>
                </div>
            </section>
        </div>
    )
}