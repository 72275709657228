import {currencyToUint, uintToCurrency} from "../../Game/components/currency-types";
import {useState} from "react";
function uniqueID() {
    return Math.floor(Math.random() * Date.now())
}
export default function Rewards({task}){
    const [state,setState] = useState(0);

    const removeReward = (index)=>{
        task.rewards.splice(index,1);
        setState(state+1);
    }
    const onChangeRewardAmount = (index,value)=>{

        value = parseInt(value)
        task.rewards[index].amount = value

    }

    const onChangeRewardType = (index,value)=>{
        let type = currencyToUint(value);
        task.rewards[index].type = type
    }
    if (task.rewards == null){
        return (
            <></>
        )
    }
    const renderList = task.rewards.map((reward, index) => {
        return (
            <div key={uniqueID()} className={'game-list-widget padding green-div'}>
                <button
                    className={'float-left remove-button force-black'} onClick={()=>removeReward(index)}>زدایش
                </button>
                <table className={'float-right'}>
                    <td>
                        <select
                            className={'select-btn center'} defaultValue={uintToCurrency(reward.type)} onChange={(e)=>{onChangeRewardType(index,e.target.value)}}>
                            <option value="Money">پول (ریال)</option>
                            <option value="Gold">طلا</option>
                            <option value="Silver">نقره</option>
                        </select>
                    </td>
                    <td>
                        نوع جایزه
                    </td>
                    <td>
                        <input className='change-name-input force-black center'
                               type='number' defaultValue={reward.amount} onChange={(e)=>{onChangeRewardAmount(index,e.target.value)}}/>
                    </td>
                    <td>
                        جایزه
                    </td>
                </table>
            </div>
        );
    })

    return(
        <>{renderList}</>
    )
}