import axios from "axios";
import {AppConstants, GetBasicAuthHeader, GetWebServiceUrl} from "../../../constants/appConstants";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import UploadElement from "../Game/components/upload";
import Avatars from "./avatars";
import DevelopHeader from "../../ui/developHeader";
import NotificationHeader from "../../ui/NotificationHeader";
import {setAvatars} from "../../../redux/actions/avatarActions";
import RequestComponent from "../requests/requestComponent";

export default function AvatarPage(){
    const admin = useSelector((state) => state.admin.admin);
    const dispatch = useDispatch();
    const navigation = useNavigate();

    const fetchAvatars = async () => {
        let response = await axios.get(GetWebServiceUrl(AppConstants.GET_AVATARS_URL), GetBasicAuthHeader(admin)).catch(err => {
            console.log(err)
        })
        dispatch(setAvatars(response.data))
    }
    useEffect(() => {
        if (admin.token === undefined) {
            navigation("/");
        }
        fetchAvatars();
    }, []);

    return (
        <>
            <DevelopHeader pageId={3}></DevelopHeader>
            <NotificationHeader></NotificationHeader>
            <section className='fix-position full-width'>
                <UploadElement type={4} clientId={""}
                               url={GetWebServiceUrl(AppConstants.UPLOAD_AVATAR_URL)}></UploadElement>
                <Avatars></Avatars>
            </section>
        </>
    )
}