import GameOption from "./gameOption";
import PrizePools from "./prizePools";
import UploadElement from "../../Game/components/upload";
import {AppConstants, GetBasicAuthHeader, GetWebServiceUrl} from "../../../../constants/appConstants";
import {useState} from "react";
import {currencyToUint, uintToCurrency} from "../../Game/components/currency-types";
import axios from "axios";
import {SetNotification} from "../../../ui/NotificationHeader";
import {useSelector} from "react-redux";
const isChangeCard = new Map();

export default function TournamentCards({tournaments}) {
    const [state, setState] = useState(0);
    const admin = useSelector((state) => state.admin.admin);

    function hasChange(index){
        if (isChangeCard == null){
            return false
        }
        return isChangeCard.get(index)
    }
    const makeChange = (index, s) => {
        if(isChangeCard != null && isChangeCard.get(index) === s){
            return
        }
        isChangeCard.set(index,s);
        setState(state + 1);

    }
    const removeTournamentTemplate = async (index) => {
        let response = await axios.post(GetWebServiceUrl(AppConstants.REMOVE_TOURNAMENT_TEMPLATE), tournaments[index], GetBasicAuthHeader(admin)).catch(err => {
            console.log("ERRR", err)
            SetNotification("خطا", err.response.status + "\n" + JSON.stringify(err.response.data), "danger");
        })
        if (response.status === 200) {
            tournaments.splice(index, 1)
            setState(state + 1);
        }
    }
    const addPrizePool = (index) => {
        let prizePool = {
            players: 0,
            rewardedPlayers: 0,
            reward: {
                amount: 0,
                type: 0,
            },
            i: 0.0,
            j: 0.0
        };
        console.log(tournaments[index])
        if (tournaments[index].prizePools == null) {
            tournaments[index].prizePools = [];
        }
        tournaments[index].prizePools.push(prizePool);
        setState(state + 1);
        makeChange(index,true)
    }

    const onChangeTournamentName = (index, value) => {
        tournaments[index].name = value
        makeChange(index,true)
    }

    const onChangeEntrance = (index, value) => {
        value = parseInt(value);
        tournaments[index].gameId = value;
        makeChange(index,true)

    }
    const onChangeEntranceType = (index, value) => {
        let type = currencyToUint(value);
        tournaments[index].entranceType = type;
        makeChange(index,true)
    }
    const onChangeStartTime = (index, value) => {
        value = new Date(value);
        tournaments[index].startTime = value;
        makeChange(index,true)

    }
    const onChangeEndTime = (index, value) => {
        value = new Date(value);
        tournaments[index].endTime = value;
        makeChange(index,true)
    }
    const onChangeGame = (index, value) => {
        value = parseInt(value)
        tournaments[index].gameId = value
        makeChange(index,true)
    }

    const onChangePublishTournament = (index, value) => {
        tournaments[index].isActive = value;
        makeChange(index,true)
    }
    const onChangeKloopSku = (index, value) => {
        tournaments[index].kloopSku = value;
        makeChange(index,true)

    }

    const saveChange = async (index) => {
        let response = await axios.post(GetWebServiceUrl(AppConstants.EDIT_TOURNAMENT_TEMPLATE), tournaments[index], GetBasicAuthHeader(admin)).catch(err => {
            console.log("ERRR", err)
            SetNotification("خطا", err.response.status + "\n" + JSON.stringify(err.response.data), "danger");
        })
        if (response.status === 200) {
            console.log("Tournament: ", response.data)
            makeChange(index,false)
        }
    }

    const renderList = tournaments.map((tournament, index) => {
        let startTime = tournament.startTime.replace("Z","")
        let endTime = tournament.endTime.replace("Z","")

        return (
            <div key={tournament.ID} className='full-width padding'>
                <div className={'tournament-card padding'}>
                    <button
                        className={'float-left remove-button force-black'}
                        onClick={() => removeTournamentTemplate(index)}>زدایش
                    </button>
                    <table className={'float-right'}>
                        <td>
                            <input className='change-name-input force-black center'
                                   type='text' defaultValue={tournament.name} onChange={(e) => {
                                onChangeTournamentName(index, e.target.value)
                            }}/>
                        </td>
                        <td>
                            نام تورنومنت
                        </td>
                    </table>

                    <table className={'float-right'}>
                        <td>
                            <select defaultValue={uintToCurrency(tournament.entranceType)} onChange={(e) => {
                                onChangeEntranceType(index, e.target.value)
                            }}
                                    className={'select-btn center'}>
                                <option value="Money">پول (ریال)</option>
                                <option value="Gold">طلا</option>
                                <option value="Silver">نقره</option>
                            </select>
                        </td>
                        <td>
                            نوع ورودی
                        </td>
                        <td>
                            <input className='change-name-input force-black center'
                                   defaultValue={tournament.entranceType} onChange={(e) => {
                                onChangeEntrance(index, e.target.value)
                            }}
                                   type='number'/>
                        </td>
                        <td>
                            ورودی
                        </td>
                    </table>

                    <table className={'float-right'}>
                        <td>
                            <input className='change-name-input force-black center' defaultValue={endTime}
                                   onChange={(e) => {
                                       onChangeEndTime(index, e.target.value)
                                   }}
                                   type='datetime-local'/>
                        </td>
                        <td>
                            زمان پایان
                        </td>
                        <td>
                            <input className='change-name-input force-black center' defaultValue={startTime}
                                   onChange={(e) => {
                                       onChangeStartTime(index, e.target.value)
                                   }}
                                   type='datetime-local'/>
                        </td>
                        <td>
                            زمان شروع
                        </td>
                    </table>
                    <table className={'float-right'}>
                        <td>
                            <GameOption defaultValue={tournament.gameId} onChange={(data) => {
                                onChangeGame(index, data)
                            }}></GameOption>
                        </td>
                        <td>
                            بازی
                        </td>
                    </table>

                    <button className={'dash-button force-black'} onClick={() => addPrizePool(index)}>
                        اضافه کردن سقف امتیازات
                    </button>
                    <PrizePools tournament={tournament} onChange={()=>makeChange(index,true)}></PrizePools>

                    <div className="center full-width padding splice ">
                        <img id='icon' className={'game-icon'} src={tournament.picUrl} alt={tournament.ID}/>
                        <p className='force-black'>تصویر تورنومنت</p>
                        <p className='force-black rtl padding'>آیکون را با پسوند png. آپلود کنید</p>
                        <UploadElement type={5} clientId={tournament.ID}
                                       url={GetWebServiceUrl(AppConstants.UPLOAD_TOURNAMENT_PIC)}></UploadElement>
                    </div>
                    <table className={'float-right'}>
                        <td>
                            <label className="switch">
                                <input type="checkbox" defaultChecked={tournament.isActive} onChange={(e) => {
                                    onChangePublishTournament(index, e.target.checked)
                                }}></input>
                                <span className="slider round"></span>
                            </label>
                        </td>
                        <td>
                            انتشار
                        </td>
                    </table>

                    <table className={'float-right'}>
                        <td>
                            <input className='change-name-input force-black center' defaultValue={tournament.kloopSku}
                                   onChange={(e) => {
                                       onChangeKloopSku(index, e.target.value)
                                   }}
                                   type='text'/>
                        </td>
                        <td>
                            شناسه کلوپ
                        </td>
                    </table>

                    <button hidden={!hasChange(index)} className={'blue-button'} onClick={() => saveChange(index)}>ذخیره</button>
                </div>

            </div>
        );
    })

    return (
        <>
            {renderList}
        </>
    )
}