import {uintToCurrency} from "../Game/components/currency-types";
import {formatNumber} from "./financePage";


export default function FinanceChart(props){
    if(props.data.labels == null) return
    const renderList = props.data.labels.map((data, index) => {
        return (
            <div>
                <table>
                    <th>{props.data.labels[index]}</th>
                    <th>~~~~</th>
                    <th>{formatNumber(props.data.data[index])}</th>
                </table>

            </div>
        );
    })

    return (
        <>{renderList}</>
    )
}