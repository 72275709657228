export const uintToCurrency = (val) => {
    switch (val) {
        case 0:
            return "Money"
        case 1:
            return "Silver"
        case 2:
            return "Gold"
    }
}

export const uintToCurrencyFa = (val) => {
    switch (val) {
        case 0:
            return "پول (ریال)"
        case 1:
            return "نقره"
        case 2:
            return "طلا"
    }
}

export const currencyToUint = (val) => {
    switch (val) {
        case "Money":
            return 0
        case "Silver":
            return 1
        case "Gold":
            return 2
    }
}