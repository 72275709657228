import DevelopHeader from "../../ui/developHeader";
import GamesComponent from "./gamesComponent";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {AppConstants, GetBasicAuthHeader, GetWebServiceUrl} from "../../../constants/appConstants";
import {setProducts} from "../../../redux/actions/productActions";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import App from "../../../App";
import NotificationHeader, {SetNotification} from "../../ui/NotificationHeader";


export default function GamesPage() {
    const games = useSelector((state) => state.allProducts.products);
    const admin = useSelector((state) => state.admin.admin);
    const navigation = useNavigate();

    const dispatch = useDispatch();
    const fetchGames = async () => {
        const response = (await axios.get(GetWebServiceUrl(AppConstants.GET_ALL_GAMES_URL), GetBasicAuthHeader(admin))
            .catch(err => {
                console.log("Err: ", err);
            }));

        if (response.status === 200) {
            dispatch(setProducts(response.data))
        }
    }
    useEffect(() => {
        if (admin.token === undefined) {
            navigation("/");
        }
        fetchGames();
    }, []);


    const addNewGame = async () => {
        const response = (await axios.get(GetWebServiceUrl(AppConstants.ADD_NEW_GAME_URL), GetBasicAuthHeader(admin))
            .catch(err => {
                console.log("Err: ", err);
            }));
        if (response.status === 200){
            SetNotification("اضافه شدن بازی جدید","بازی جدید با موفقیت اضافه شد","success");
        }else{
            SetNotification("خطا", response.status + "\n" + response.data,"danger");
        }
        fetchGames();
    };

    console.log("Games :", games);
    return (
        <div>
            <DevelopHeader pageId={1}></DevelopHeader>
            <NotificationHeader></NotificationHeader>
            <section className='fix-position full-width'>
                <div className="game-list">
                    <button onClick={addNewGame} className='dash-button'>بازی جدید +</button>
                    <div id='games'>
                        <GamesComponent/>
                    </div>
                </div>
            </section>
        </div>

    );
}