import {combineReducers} from "redux";
import {productsReducer, selectedProductsReducer} from "./productsReducer";
import {adminReducer} from "./adminReducer";
import {gameModeReducer} from "./gameModeReducer";
import {requestReducer} from "./requestReducer";
import {avatarsReducer} from "./avatarReducer";
import {financeReducer} from "./financeReducer";
import {tournamentReducer} from "./tournamentReducer";
import {bonusTasksReducer, dailyTasksReducer} from "./taskReducer";


const reducers = combineReducers({
    allProducts: productsReducer,
    product: selectedProductsReducer,
    gameModes: gameModeReducer,
    requests: requestReducer,
    admin: adminReducer,
    allAvatars :avatarsReducer,
    finance :financeReducer,
    allTournaments: tournamentReducer,
    dailyTasks: dailyTasksReducer,
    bonusTasks: bonusTasksReducer,
});

export default reducers;