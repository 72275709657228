import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import axios from "axios";
import {AppConstants, GetBasicAuthHeader, GetWebServiceUrl} from "../../../constants/appConstants";
import NotificationHeader, {SetNotification} from "../../ui/NotificationHeader";
import {setTournaments} from "../../../redux/actions/tournamentActions";
import {setBonusTasks, setDailyTasks} from "../../../redux/actions/taskActions";
import DevelopHeader from "../../ui/developHeader";
import TournamentCards from "../tournaments/components/tournamentCards";
import DailyTasksCards from "./components/dailyTasksCards";
import BonusTasksCards from "./components/bonusTasksCards";

export default function TaskPage() {
    const dailyTasks = useSelector((state) => state.dailyTasks.tasks)
    const bonusTasks = useSelector((state) => state.bonusTasks.tasks)
    const admin = useSelector((state) => state.admin.admin);
    const dispatch = useDispatch();
    const navigation = useNavigate();

    useEffect(() => {
        if (admin.token === undefined) {
            navigation("/");
        }
        fetchTasks();
    }, []);

    const fetchTasks = async () => {
        let dailyResponse = await axios.get(GetWebServiceUrl(AppConstants.GET_DAILY_TASKS), GetBasicAuthHeader(admin)).catch(err => {
            console.log("ERRR", err)
            SetNotification("خطا", err.response.status + "\n" + JSON.stringify(err.response.data), "danger");
        })
        let bonusResponse = await axios.get(GetWebServiceUrl(AppConstants.GET_BONUS_TASKS), GetBasicAuthHeader(admin)).catch(err => {
            console.log("ERRR", err)
            SetNotification("خطا", err.response.status + "\n" + JSON.stringify(err.response.data), "danger");
        })
        if (dailyResponse.status === 200) {
            console.log("Daily Tasks: ",dailyResponse.data)
            dispatch(setDailyTasks(dailyResponse.data))
        }
        if (bonusResponse.status === 200) {
            console.log("Bonus Tasks: ",bonusResponse.data)
            dispatch(setBonusTasks(bonusResponse.data))
        }
    }

    const addNewDailyTask = async () => {
        let response = await axios.get(GetWebServiceUrl(AppConstants.ADD_DAILY_TASK), GetBasicAuthHeader(admin)).catch(err => {
            console.log("ERRR", err)
            SetNotification("خطا", err.response.status + "\n" + JSON.stringify(err.response.data), "danger");
        })
        if (response.status === 200) {
            console.log('response', response.data);
            fetchTasks()
            // openPage(AppConstants.SITE_ADDR + '/games')
        }
    }

    const addNewBonusTask = async () => {
        let response = await axios.get(GetWebServiceUrl(AppConstants.ADD_BONUS_TASK), GetBasicAuthHeader(admin)).catch(err => {
            console.log("ERRR", err)
            SetNotification("خطا", err.response.status + "\n" + JSON.stringify(err.response.data), "danger");
        })
        if (response.status === 200) {
            console.log('response', response.data);
            fetchTasks()
            // openPage(AppConstants.SITE_ADDR + '/games')
        }
    }

    return (
        <>
            <DevelopHeader pageId={6}></DevelopHeader>
            <NotificationHeader></NotificationHeader>

            <section className='fix-position full-width'>
                <div className="game-list">
                    <table className={'full-width'}>
                        <th className={'padding'}>
                            <button onClick={addNewDailyTask} className='dash-button'>تسک روزانه جدید +</button>
                        </th>
                        <th>
                            <button onClick={addNewBonusTask} className='dash-button'>تسک پاداش جدید +</button>
                        </th>
                    </table>
                    <table className={'full-width'}>
                        <th className={'padding'}>
                            <div id='daily-tasks'>
                                <DailyTasksCards dailyTasks={dailyTasks}></DailyTasksCards>
                            </div>
                        </th>
                        <th>
                            <div id='bonus-tasks'>
                                <BonusTasksCards bonusTasks={bonusTasks}></BonusTasksCards>
                            </div>
                        </th>
                    </table>


                </div>
            </section>
        </>
    )

}