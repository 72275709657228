import {useNavigate, useParams} from "react-router-dom";
import UploadElement from "./components/upload";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {AppConstants, GetBasicAuthHeader, GetWebServiceUrl} from "../../../constants/appConstants";
import {selectedProduct} from "../../../redux/actions/productActions";
import {useEffect, useState} from "react";
import GameMode from "./components/gameMode";
import {setGameModes} from "../../../redux/actions/gameModesActions.js";
import NotificationHeader, {SetNotification} from "../../ui/NotificationHeader";


export default function GamePage() {
    const game = useSelector(state => state.product);
    const admin = useSelector(state => state.admin.admin)
    const params = useParams();
    const dispatch = useDispatch();
    const {clientId} = params;
    const navigation = useNavigate();
    const [state, setState] = useState(0);


    const fetchGame = async () => {
        let response = await axios.get(GetWebServiceUrl(AppConstants.GET_ALL_GAMES_URL) + "/" + clientId, GetBasicAuthHeader(admin)).catch(err => {
            console.log(err)
        })
        dispatch(selectedProduct(response.data))
        dispatch(setGameModes(response.data.modes))
    }

    useEffect(() => {

        if (admin.token === undefined || game.clientId === undefined) {
            navigation("/");
        }
        fetchGame();
    }, []);

    if (admin.token === undefined || game.clientId === undefined) {
        return;
    }

    const addGameMode = async () => {
        const response = (await axios.post(GetWebServiceUrl(AppConstants.ADD_NEW_GAME_MODE_URL), {"clientId": game.clientId}, GetBasicAuthHeader(admin))
            .catch(err => {
                console.log("Err: ", err);
            }));

        if (response.status === 200){
            SetNotification("اضافه شدن مود بازی","مود بازی با موفقیت اضافه شد","success");
        }else{
            SetNotification("خطا", response.status + "\n" + response.data,"danger");
        }
        fetchGame();
    };


    const onChangeName = value => {
        game.name = value;
        setState(state + 1);
    }

    const saveGame = async () => {
        let data = {
            "clientId": game.clientId,
            "name": game.name,
        }
        let response = await axios.post(GetWebServiceUrl(AppConstants.SAVE_GAME_URL), data, GetBasicAuthHeader(admin)).catch(err => {
            console.log(err);
        });
        if (response.status === 200){
            SetNotification("ذخیره نام بازی","نام بازی با موفقیت تغییر کرد","success");
        }else{
            SetNotification("خطا", response.status + "\n" + response.data,"danger");
        }
    }

    const removeTutorials = async ()=>{
        let data = {
            "clientId": game.clientId,
            "name": game.name,
        }
        let response = await axios.post(GetWebServiceUrl(AppConstants.DELETE_TUTORIAL_URL), data, GetBasicAuthHeader(admin)).catch(err => {
            console.log(err);
        });
        if (response.status === 200){
            SetNotification("حذف همه عکس های توتوریال","توتوریال پاک شد","success");
        }else{
            SetNotification("خطا", response.status + "\n" + response.data,"danger");
        }
    }

    return (
        <>
            <NotificationHeader></NotificationHeader>
            <section className='fix-position full-width'>
                <div className='full-width'>
                    <div className='white padding-left'>
                        <td>
                            <tr>
                                <div className='game-list-info'>
                                    <p>Name: <b>{game.name}</b></p>
                                </div>
                                <div className='game-list-info'>
                                    <input onChange={(e) => onChangeName(e.target.value)} className='change-name-input'
                                           type='text'/>
                                </div>
                                <div className='game-list-info'>
                                    <button onClick={saveGame} className='change-button'>ذخیره</button>
                                </div>
                            </tr>
                            <tr className='float-left'>
                                <p>Admin Email: <b>{game.admin.email}</b></p>
                                <p>Client ID: <b>{game.clientId}</b></p>
                                <p>Client Secret: <b>{game.clientSecret}</b></p>
                                <p>IsAvailable?: <b>{game.isAvailable.toString()}</b></p>
                                <p>Updated At: <b>{game.UpdatedAt}</b></p>
                            </tr>
                        </td>
                    </div>
                </div>

                <table>
                    <td className=' eighty-width padding'>
                        <a className='padding float-left full-width' href={game.gamePath + "?test"} target="_blank">
                            <button className='green-button'>تست بازی</button>
                        </a>

                        <div className=" full-width center padding splice">
                            <p className='white'>WEB GL</p>
                            <p className='white rtl padding'>فایل بازی را با پسوند zip. آپلود کنید</p>
                            <UploadElement type={0} clientId={game.clientId}
                                           url={GetWebServiceUrl(AppConstants.UPLOAD_WEB_GL_URL)}></UploadElement>
                        </div>
                        <div className="center full-width padding splice">
                            <img id='icon' className={'game-icon'} src={game.imagePath} alt={game.name}/>
                            <p className='white'>آیکون</p>
                            <p className='white rtl padding'>آیکون را با پسوند png. آپلود کنید</p>
                            <UploadElement type={1} clientId={game.clientId}
                                           url={GetWebServiceUrl(AppConstants.UPLOAD_ICON_URL)}></UploadElement>
                        </div>
                        <div className="center full-width padding splice">
                            <img id='banner' className={'game-banner'} src={game.bannerPath} alt={game.name}/>
                            <p className='white'>بنر</p>
                            <p className='white rtl padding'>بنر را با پسوند png. آپلود کنید</p>

                            <UploadElement type={2} clientId={game.clientId}
                                           url={GetWebServiceUrl(AppConstants.UPLOAD_BANNER_URL)}></UploadElement>
                        </div>
                        <div className='game-list-info'>
                            <button onClick={removeTutorials} className='change-button'>پاک کردن عکس های توتوریال</button>
                        </div>
                        <div className="center full-width padding splice">
                            <p className='white'>توتوریال فارسی</p>
                            <p className='white rtl padding'>توتوریال ها را با پسوند png. آپلود کنید</p>

                            <UploadElement type={2} clientId={game.clientId}
                                           url={GetWebServiceUrl(AppConstants.UPLOAD_TUTORIAL_URL)}></UploadElement>
                        </div>
                    </td>
                    <td className={'mods padding'}>
                        <h1 className={'white center'}>مودها</h1>
                        <div className={'center'}>
                            <button onClick={addGameMode} className='dash-button'>مود جدید +</button>
                            <div>
                                <GameMode/>
                            </div>
                        </div>
                    </td>
                </table>
            </section>
        </>
    );
}