import {currencyToUint, uintToCurrency} from "./currency-types";
import { useState} from "react";
import {useSelector} from "react-redux";
import {saveGameMode} from "./gameMode";
function uniqueID() {
    return Math.floor(Math.random() * Date.now())
}
export default function GameReward(props) {
    let mode = props.mode;
    const [state, setState] = useState(0)
    let admin = useSelector(state=>state.admin.admin)
    const onChangeType = (index, value) => {
        mode.rewards[index].type = currencyToUint(value);
        console.log(mode.rewards[index].type);
    }

    const onChangeAmount = (index, value) => {
        mode.rewards[index].amount = parseInt(value);
        console.log(mode.rewards[index].amount);
    }

    const removeReward = async (e,index) => {
        mode.rewards.splice(index,1);
        setState(state+1);
        saveGameMode(mode,admin)
    }

    if (mode.rewards != null) {
        const renderList = mode.rewards.map((reward, index) => {
            return (
                <div key={uniqueID()} className={'reward-wrapper center full-width sep-table'}>
                    <button onClick={() => removeReward(index)}
                            className={'float-right remove-button force-black'}>زدایش
                    </button>
                    <table className={'float-right sep-table'}>
                        <td>
                            <input onChange={(e) => onChangeAmount(index, e.target.value)} className='mode-input center'
                                   type='number' defaultValue={reward.amount}/>
                        </td>
                        <td>
                            میزان
                        </td>
                    </table>
                    <table className={'float-right sep-table'}>
                        <td>
                            <select onChange={(e) => onChangeType(index, e.target.value)}
                                    className={'select-blue-button center'} defaultValue={uintToCurrency(reward.type)}>
                                <option value="Money">پول (ریال)</option>
                                <option value="Gold">طلا</option>
                                <option value="Silver">نقره</option>
                            </select>
                        </td>
                        <td>
                            نوع
                        </td>
                    </table>
                </div>
            )
        });


        return (
            <>{renderList}</>
        )
    }
}