import {useState} from "react";
import {useSelector} from "react-redux";
import {currencyToUint, uintToCurrency} from "../../Game/components/currency-types";
import {taskToUint, uintToTask} from "./taskTypes";
import GameOption from "../../tournaments/components/gameOption";
import axios from "axios";
import {AppConstants, GetBasicAuthHeader, GetWebServiceUrl} from "../../../../constants/appConstants";
import NotificationHeader, {SetNotification} from "../../../ui/NotificationHeader";
import Rewards from "./rewards";


export default function BonusTasksCards({bonusTasks}) {

    const [state, setState] = useState(0);
    const admin = useSelector((state) => state.admin.admin);
    const removeTask = async (index) => {
        let response = await axios.post(GetWebServiceUrl(AppConstants.REMOVE_BONUS_TASK), bonusTasks[index], GetBasicAuthHeader(admin)).catch(err => {
            console.log("ERRR", err)
            SetNotification("خطا", err.response.status + "\n" + JSON.stringify(err.response.data), "danger");
        })
        if (response.status === 200) {
            bonusTasks.splice(index, 1)
            setState(state + 1);
        }
    }
    const onChangeTaskName = (index, value) => {
        bonusTasks[index].name = value
    }
    const onChangeMaxValue = (index, value) => {
        value = parseInt(value);
        bonusTasks[index].maxValue = value
    }


    const onChangePublishTask = (index, value) => {
        bonusTasks[index].isActive = value;
    }

    const saveChange = async (index) => {
        let response = await axios.post(GetWebServiceUrl(AppConstants.EDIT_BONUS_TASK), bonusTasks[index], GetBasicAuthHeader(admin)).catch(err => {
            console.log("ERRR", err)
            SetNotification("خطا", err.response.status + "\n" + JSON.stringify(err.response.data), "danger");
        })
        if (response.status === 200) {
            console.log("bonusTasks: ", response.data)
        }
    }

    const addReward = (index) => {
        let reward = {
            amount: 0,
            type: 0,
        };
        if (bonusTasks[index].rewards == null) {
            bonusTasks[index].rewards = [];
        }
        bonusTasks[index].rewards.push(reward);
        setState(state + 1);
    }

    const renderList = bonusTasks.map((task, index) => {
        return (
            <>
                <div key={task.ID} className='full-width padding'>
                    <div className={'tournament-card padding'}>
                        <p>پاداش</p>
                        <button
                            className={'float-left remove-button force-black'}
                            onClick={() => removeTask(index)}>زدایش
                        </button>
                        <table className={'float-right'}>
                            <td>
                                <input className='change-name-input force-black center'
                                       defaultValue={task.maxValue} onChange={(e) => {
                                    onChangeMaxValue(index, e.target.value)
                                }} type='number'/>
                            </td>
                            <td>
                                مقدار
                            </td>
                            <td>
                                <input className='change-name-input force-black center'
                                       type='text' defaultValue={task.name} onChange={(e) => {
                                    onChangeTaskName(index, e.target.value)
                                }}/>
                            </td>
                            <td>
                                نام
                            </td>

                        </table>

                        <table className={'float-right'}>
                            <td>
                                <label className="switch">
                                    <input type="checkbox" defaultChecked={task.isActive} onChange={(e) => {
                                        onChangePublishTask(index, e.target.checked)
                                    }}></input>
                                    <span className="slider round"></span>
                                </label>
                            </td>
                            <td>
                                انتشار
                            </td>
                        </table>
                        <button className={'dash-button force-black'} onClick={() => addReward(index)}>
                            اضافه کردن جایزه +
                        </button>
                        <Rewards task={task}></Rewards>
                        <button className={'blue-button'} onClick={() => saveChange(index)}>ذخیره</button>
                    </div>
                </div>
            </>
        )
    })

    return (
        <>
            <NotificationHeader></NotificationHeader>
            {renderList}
        </>
    )
}