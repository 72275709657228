import React, {useCallback, useState} from 'react';
import DropBox from './dropBox';
import axios from "axios";
import {GetBasicAuthHeader} from "../../../../constants/appConstants";
import {useDispatch, useSelector} from "react-redux";
import {loadAuth2WithProps} from "gapi-script";
import {selectedProduct} from "../../../../redux/actions/productActions";
import NotificationHeader, {SetNotification, SetNotificationWithoutDismiss} from "../../../ui/NotificationHeader";
import {setAvatars} from "../../../../redux/actions/avatarActions";

export default function UploadElement(props) {

    const [state,setState]=  useState(0)

    const admin = useSelector(state => state.admin.admin);
    const game = useSelector(state => state.product);
    const avatars = useSelector(state => state.allAvatars.avatars);
    const dispatch = useDispatch();

    const url = props.url;
    const clientId = props.clientId.toString();
    const type = props.type;
    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.map((file, index) => {
            const reader = new FileReader();

            reader.onload = function (e) {

            };

            reader.readAsDataURL(file);

            let formData = new FormData();

            let data = {
                "adminId": admin.ID,
                "clientId": clientId,
            }
            formData.append("file", file);
            formData.append("data", JSON.stringify(data));
            axios.post(url, formData, GetBasicAuthHeader(admin)).catch(err => console.log(err)).then(response => {
                console.log(response);
                switch (type) {
                    case 0:
                        console.log("set webgl", response.data)
                        game.gamePath = response.data;
                        break
                    case 1:
                        console.log("set icon", response.data)
                        game.imagePath = response.data;
                        break
                    case 2:
                        console.log("set banner", response.data)
                        game.bannerPath = response.data;
                        break
                    case 3:
                        console.log("set tutorials", response.data)
                        game.tutorials = response.data;
                        break
                    case 4:
                        console.log("set avatar", response.data)
                        avatars.append(response.data)
                        dispatch(setAvatars(avatars))
                        break
                    case 5:
                        console.log("set tournament pic",response.data)
                        setState(state + 1)
                }
                SetNotificationWithoutDismiss("اتمام آپلود", file.name, "success");
                dispatch(selectedProduct(game));
            });
            console.log("UPLOAD ", url, file)
            SetNotificationWithoutDismiss("در حال آپلود", file.name, "success");

            return file;
        });
    }, []);

    return (
        <>
            <NotificationHeader></NotificationHeader>
            <div className="dropbox">
                <DropBox onDrop={onDrop}/>
            </div>
        </>
    );
}