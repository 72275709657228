import {ActionTypes} from "../constants/action-types";


const initialState = {
    tournaments: [],
};

export const tournamentReducer = (state = initialState, {type, payload}) =>{
    switch (type){
        case ActionTypes.SET_TOURNAMENTS:
            return {...state,tournaments:payload}
        default:
            return state
    }
};