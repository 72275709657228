import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {AppConstants, GetBasicAuthHeader, GetWebServiceUrl} from "../../../constants/appConstants";
import {SetNotification} from "../../ui/NotificationHeader";
import {currencyToUint, uintToCurrency} from "../Game/components/currency-types";
import {setAvatars} from "../../../redux/actions/avatarActions";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

export default function Avatars() {
    const avatars = useSelector((state) => state.allAvatars.avatars)
    const admin = useSelector((state) => state.admin.admin);
    const dispatch = useDispatch();
    const navigation = useNavigate();

    const fetch = async () => {
        let response = await axios.get(GetWebServiceUrl(AppConstants.GET_AVATARS_URL), GetBasicAuthHeader(admin)).catch(err => {
            console.log(err)
        })
        dispatch(setAvatars(response.data))
    }
    useEffect(() => {
        if (admin.token === undefined) {
            navigation("/");
        }
        fetch();
    }, []);

    const onChangePrice = (index, value) => {
        avatars[index].price = parseInt(value)

    }
    const onChangePriceType = (index, value) => {
        avatars[index].priceType = currencyToUint(value);
    }

    const save = async (index) => {
        let avatar = avatars[index]
        let data = {
            "avtarId": avatar.ID,
            "price": avatar.price,
            "priceType": avatar.priceType
        }
        let response = await axios.post(GetWebServiceUrl(AppConstants.UPDATE_AVATAR_URL), data, GetBasicAuthHeader(admin)).catch(err => {
            console.log(err);
        });
        if (response.status === 200) {
            dispatch(setAvatars(avatars))
            SetNotification("بروزرسانی آواتار", "بروزرسانی انجام شد", "success");
        } else {
            SetNotification("خطا", response.status + "\n" + response.data, "danger");
        }
    }


    const remove = async (index) => {
        let avatar = avatars[index]
        let data = {
            "avatarId": avatar.ID,
        }
        let response = await axios.post(GetWebServiceUrl(AppConstants.REMOVE_AVATAR_URL), data, GetBasicAuthHeader(admin)).catch(err => {
            console.log(err);
        });
        if (response.status === 200) {
            avatars[index].remove(avatars[index])
            dispatch(setAvatars(avatars))
            SetNotification("حذف آواتار", "آواتار حذف شد", "success");
        } else {
            SetNotification("خطا", response.status + "\n" + response.data, "danger");
        }
    }

    const renderList = avatars.map((avatar, index) => {
        return (
            <div key={avatar.ID} className={'game-list-widget'}>
                <input className='mode-input center force-white'
                       type='number' defaultValue={avatar.price} onChange={(e) => onChangePrice(index, e.target.value)}/>
                <select onChange={(e) => onChangePriceType(index, e.target.value)}
                        className={'select-blue-button center'} defaultValue={uintToCurrency(avatar.priceType)}>
                    <option value="Money">پول (ریال)</option>
                    <option value="Gold">طلا</option>
                    <option value="Silver">نقره</option>
                </select>
                <img src={avatar.url}/>
                <button onClick={() => save(index)} className='change-button'>ثبت تغییرات</button>
                <button onClick={() => remove(index)} className={'remove-button'}>زدایش</button>
            </div>
        );
    })

    return (
        <>{renderList}</>
    )
}