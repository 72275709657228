import NotificationHeader, {SetNotification} from "../../ui/NotificationHeader";
import DevelopHeader from "../../ui/developHeader";
import TournamentCards from "./components/tournamentCards";
import axios from "axios";
import {AppConstants, GetBasicAuthHeader, GetWebServiceUrl} from "../../../constants/appConstants";
import {useDispatch, useSelector} from "react-redux";
import {setTournaments} from "../../../redux/actions/tournamentActions";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";


export default function TournamentPage() {
    const tournaments = useSelector((state) => state.allTournaments.tournaments)
    const admin = useSelector((state) => state.admin.admin);
    const dispatch = useDispatch();
    const navigation = useNavigate();

    useEffect(() => {
        if (admin.token === undefined) {
            navigation("/");
        }
        fetchTournaments();
    }, []);


    const fetchTournaments = async () => {
        let response = await axios.get(GetWebServiceUrl(AppConstants.GET_TOURNAMENT_TEMPLATES), GetBasicAuthHeader(admin)).catch(err => {
            console.log("ERRR", err)
            SetNotification("خطا", err.response.status + "\n" + JSON.stringify(err.response.data), "danger");
        })
        if (response.status === 200) {
            console.log("Tournaments: ",response.data)
            dispatch(setTournaments(response.data))
        }
    }

    const syncTournaments = async () => {

        let response = await axios.get(GetWebServiceUrl(AppConstants.SYNC_TOURNAMENT_TEMPLATES), GetBasicAuthHeader(admin)).catch(err => {
            console.log("ERRR", err)
            SetNotification("خطا", err.response.status + "\n" + JSON.stringify(err.response.data), "danger");
        })
        if (response.status === 200) {
            SetNotification("سینک شدن","با موفقیت تورنومنت ها سینک شدند","success");
            console.log("Tournaments: ",response.data)
        }
    }

    const addNewTournament = async () => {
        let response = await axios.get(GetWebServiceUrl(AppConstants.ADD_NEW_TOURNAMENT_TEMPLATE), GetBasicAuthHeader(admin)).catch(err => {
            console.log("ERRR", err)
            SetNotification("خطا", err.response.status + "\n" + JSON.stringify(err.response.data), "danger");
        })
        if (response.status === 200) {
            console.log('response', response.data);
            fetchTournaments()
            // openPage(AppConstants.SITE_ADDR + '/games')
        }
    }


    return (
        <>
            <DevelopHeader pageId={5}></DevelopHeader>
            <NotificationHeader></NotificationHeader>

            <section className='fix-position full-width'>
                <div className="game-list">
                    <button className='green-button' onClick={syncTournaments}>سینک کردن</button>

                    <button onClick={addNewTournament} className='dash-button'>تورنومنت جدید +</button>
                    <div id='tournaments'>
                        <TournamentCards tournaments={tournaments}/>
                    </div>
                </div>
            </section>


        </>
    )
}