
export const uintToTask = (val) => {
    switch (val) {
        case 0:
            return "Win"
        case 1:
            return "Join"
        case 2:
            return "Score"
    }
}

export const uintToTaskFa = (val) => {
    switch (val) {
        case 0:
            return "برنده شدن"
        case 1:
            return "بازی کردن"
        case 2:
            return "امتیاز"
    }
}

export const taskToUint = (val) => {
    switch (val) {
        case "Win":
            return 0
        case "Join":
            return 1
        case "Score":
            return 2
    }
}