import {BrowserRouter, Route, Routes} from "react-router-dom";
import RegisterPage from "../pages/register/registerPage";
import LoginPage from "../pages/login/loginPage";
import RequestsPage from "../pages/requests/requestsPage";
import GamePage from "../pages/Game/gamePage";
import ActivationPage from "../pages/activation";
import GamesPage from "../pages/Games/gamesPage";
import ProfilePage from "../pages/profilePage";
import AvatarPage from "../pages/avatar/avatarPage";
import FinancePage from "../pages/finance/financePage";
import TournamentPage from "../pages/tournaments/tournamentPage";
import TaskPage from "../pages/tasks/taskPage";


export default function AppRoutes() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<LoginPage/>} />
                <Route path="/register" element={<RegisterPage/>} />
                <Route path="/games" element={<GamesPage/>} />
                <Route path="/games/:clientId" element={<GamePage/>} />
                <Route path="/requests" element={<RequestsPage/>} />
                <Route path="/activation" element={<ActivationPage/>} />
                <Route path="/profile" element={<ProfilePage/>} />
                <Route path="/avatars" element={<AvatarPage/>} />
                <Route path="/finance" element={<FinancePage/>} />
                <Route path="/tournaments" element={<TournamentPage/>} />
                <Route path="/tasks" element={<TaskPage/>} />
            </Routes>
        </BrowserRouter>
    );
}
