import LoginForm from "./components/loginForm";
import '../../ui/style.css'


export default function LoginPage(){
    return(
        <div>
                <LoginForm></LoginForm>
        </div>
    );
}