import {useSelector} from "react-redux";
import DevelopHeader from "../ui/developHeader";
import RequestComponent from "./requests/requestComponent";
import axios from "axios";
import {AppConstants, GetBasicAuthHeader, GetWebServiceUrl, LoginBasicAuth} from "../../constants/appConstants";
import NotificationHeader, {SetNotification} from "../ui/NotificationHeader";


export default function ProfilePage() {
    var admin = useSelector(state => state.admin.admin)
    let password;
    const onChangePassword = (value) => {
        password = value;
    }

    const changePassword = async ()=>{
        if (password === null || password === ""){
            SetNotification("خطا", "پسورد نباید خالی باشد", "danger");
            return
        }
        let response = await axios.post(GetWebServiceUrl(AppConstants.CHANGE_PASSWORD), {"password": password}, GetBasicAuthHeader(admin)).catch(err => {
            console.log("ERR",err)
            SetNotification("خطا", err.response.status + "\n" + JSON.stringify(err.response.data), "danger");
        })

        if (response.status === 200){
            SetNotification("تغییر پسورد","پسورد با موفقیت تغییر کرد","success");
        }
    }


    const GetRole = (role) => {
        switch (role) {
            case 0:
                return "Developer";
            case 1:
                return "Admin";
            case 2:
                return "God";
        }
    }

    return (
        <>
            <DevelopHeader pageId={0}> </DevelopHeader>
            <NotificationHeader></NotificationHeader>
            <section className='fix-position full-width'>
                <div className="game-list">
                    <div className={'white float-left'} id='profile'>
                        <p>ID: <b>{admin.ID}</b></p>
                        <p>Full Name: <b>{admin.fullName}</b></p>
                        <p>Email: <b>{admin.email}</b></p>
                        <p>Google ID: <b>{admin.google_id}</b></p>
                        <p>Role: <b>{GetRole(admin.role)}</b></p>
                    </div>
                    <div className={'padding-left white float-left'} id='profile'>
                        <p>پسورد جدید</p>
                        <input onChange={(e) => onChangePassword(e.target.value)} type={"password"}
                               className={'field padding-left'}></input>
                        <button onClick={changePassword} className={'dash-button'}>ثبت پسورد جدید</button>
                    </div>

                </div>
            </section>

            <div className=' white padding full-width'>

            </div>
        </>

    )
}