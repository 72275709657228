import {useState} from "react";
import {currencyToUint, uintToCurrency} from "../../Game/components/currency-types";

function uniqueID() {
    return Math.floor(Math.random() * Date.now())
}
export default function PrizePools({tournament,onChange}){

    const [state,setState] = useState(0);

    const removePrizePool = (index)=>{
        tournament.prizePools.splice(index,1);
        setState(state+1);
        onChange()
    }

    const onChangePlayersCount=(index,value)=>{
        value = parseInt(value)
        tournament.prizePools[index].players = value
        onChange()
    }

    const onChangeRewardedPLayersCount = (index,value)=>{
        value = parseInt(value)
        tournament.prizePools[index].rewardedPlayers = value
        onChange()

    }

    const onChangeRewardAmount = (index,value)=>{

        value = parseInt(value)
        tournament.prizePools[index].reward.amount = value
        onChange()

    }

    const onChangeRewardType = (index,value)=>{
        let type = currencyToUint(value);
        tournament.prizePools[index].reward.type = type
        onChange()

    }

    const onChangeIFactor = (index,value)=>{
        value = parseFloat(value)
        tournament.prizePools[index].i = value
        onChange()

    }

    const onChangeJFactor = (index,value)=>{
        value = parseFloat(value)
        tournament.prizePools[index].j = value
        onChange()
    }


    if (tournament.prizePools == null){
        return (
            <></>
        )
    }
    const renderList = tournament.prizePools.map((data, index) => {
        return (
            <div key={uniqueID()} className={'game-list-widget padding green-div'}>
                <button
                    className={'float-left remove-button force-black'} onClick={()=>removePrizePool(index)}>زدایش
                </button>
                <table className={'float-right'}>
                    <td>
                        <input className='change-name-input force-black center'
                               type='number' defaultValue={data.players} onChange={(e)=>{onChangePlayersCount(index,e.target.value)}}/>
                    </td>
                    <td>
                        تعداد بازیکنان
                    </td>
                </table>
                <table className={'float-right'}>
                    <td>
                        <select
                            className={'select-btn center'} defaultValue={uintToCurrency(data.reward.type)} onChange={(e)=>{onChangeRewardType(index,e.target.value)}}>
                            <option value="Money">پول (ریال)</option>
                            <option value="Gold">طلا</option>
                            <option value="Silver">نقره</option>
                        </select>
                    </td>
                    <td>
                        نوع جایزه
                    </td>
                    <td>
                        <input className='change-name-input force-black center'
                               type='number' defaultValue={data.reward.amount} onChange={(e)=>{onChangeRewardAmount(index,e.target.value)}}/>
                    </td>
                    <td>
                        جایزه
                    </td>
                </table>
                <table className={'float-right'}>
                    <td>
                        <input className='change-name-input force-black center'
                               type='number' defaultValue={data.j} onChange={(e)=>{onChangeJFactor(index,e.target.value)}}/>
                    </td>
                    <td>
                        فاکتور جی
                    </td>
                    <td>
                        <input className='change-name-input force-black center'
                               type='number' defaultValue={data.i} onChange={(e)=>{onChangeIFactor(index,e.target.value)}}/>
                    </td>
                    <td>
                        فاکتور آی
                    </td>
                </table>
                <table className={'float-right'}>
                    <td>
                        <input className='change-name-input force-black center'
                               type='number' defaultValue={data.rewardedPlayers} onChange={(e)=>{onChangeRewardedPLayersCount(index,e.target.value)}}/>
                    </td>
                    <td>
                        نفراتی که جایزه دریافت میکنند
                    </td>
                </table>
            </div>
        );
    })

    return(
        <>{renderList}</>
    )
}