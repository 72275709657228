export const ActionTypes = {
    SET_ADMIN: "SET_ADMIN",
    SET_PRODUCTS: "SET_PRODUCTS",
    SELECTED_PRODUCT: "SELECTED_PRODUCT",
    SET_GAME_MODES: "SET_GAME_MODES",
    SET_REQUESTS: "SET_REQUESTS",
    REMOVE_REWARD: "REMOVE_REWARD",
    REMOVE_SELECTED_PRODUCT: "REMOVE_SELECTED_PRODUCT",
    SET_AVATARS :"SET_AVATARS",
    SET_FINANCE :"SET_FINANCE",
    SET_TOURNAMENTS : "SET_TOURNAMENTS",
    SET_DAILY_TASKS : "SET_DAILY_TASKS",
    SET_BONUS_TASKS : "SET_BONUS_TASKS",
};